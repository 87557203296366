<template>
    <div>
        <van-form>
            <van-field v-model="guildInfo.u_id" label="公会ID" readonly />
            <van-field v-model="guildInfo.union_name" label="公会名称" readonly />
        </van-form>
        <div style="margin-top:10px">
            <van-tabs v-model="active1" @disabled="onClickDisabled">
                <van-tab title="流水"></van-tab>
                <van-tab title="聊天" disabled></van-tab>
            </van-tabs>

        </div>
        <div style="margin-top:20px">
            <van-tabs v-model="active2" type="card" color="#3399ff" @change="tabClick" swipeable animated>
                <van-tab :name="item.name" :title="item.title" v-for="(item,index) in tabs" :key="index">
                    <div class="list_box">
                        <!-- <van-loading size="24px" vertical v-if="loading">加载中...</van-loading> -->
                        <div class="table_tip">
                            <div>{{tip1}}</div>
                            <van-button type="info" size="small" @click="toUserWater(0)">查看详情</van-button>
                        </div>
                        <div class="table">
                            <div class="table top_table">
                                <div class="table_item">
                                    <div class="table_title">经典礼物</div>
                                    <div class="table_value">{{list1.price_1}}</div>
                                </div>
                                <div class="table_item">
                                    <div class="table_title">背包其他</div>
                                    <div class="table_value">{{list1.price_265}}</div>
                                </div>
                                <div class="table_item">
                                    <div class="table_title">背包85</div>
                                    <div class="table_value">{{list1.price_285}}</div>
                                </div>
                            </div>
                            <div class="table bottom_table">
                                <div class="table_item">
                                    <div class="table_title">组队开黑</div>
                                    <div class="table_value">{{list1.game_value}}</div>
                                </div>
                                <div class="table_item">
                                    <div class="table_title"></div>
                                    <div class="table_value"></div>
                                </div>
                              
                                <div class="table_item">
                                    <div class="table_title"></div>
                                    <div class="table_value"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 昨日流水 -->
                    <div class="list_box">
                        <div class="table_tip">
                            <div>{{tip2}}</div>
                            <van-button type="info" size="small" @click="toUserWater(1)">查看详情</van-button>
                        </div>
                        <div class="table">
                            <div class="table top_table">
                                <div class="table_item">
                                    <div class="table_title">经典礼物</div>
                                    <div class="table_value">{{list2.price_1}}</div>
                                </div>
                                <div class="table_item">
                                    <div class="table_title">背包其他</div>
                                    <div class="table_value">{{list2.price_265}}</div>
                                </div>
                                <div class="table_item">
                                    <div class="table_title">背包85</div>
                                    <div class="table_value">{{list2.price_285}}</div>
                                </div>
                            </div>
                            <div class="table bottom_table">
                                <div class="table_item">
                                    <div class="table_title">组队开黑</div>
                                    <div class="table_value">{{list2.game_value}}</div>
                                </div>
                                <div class="table_item">
                                    <div class="table_title"></div>
                                    <div class="table_value"></div>
                                </div>
                              
                                <div class="table_item">
                                    <div class="table_title"></div>
                                    <div class="table_value"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>
<script>
import { GuildWater } from "@/api/api";
export default {
    data() {
        return {
            guildInfo: {},
            tabs: [{title:'按日',name:'a'}, {title:'按周',name:'b'},{title:'按月',name:'c'} ],
            active1: 0,
            active2: 'a',
            type:1,
            tip1:'今日流水',
            tip2:'昨日流水',
            union_id: '',
            list1: [],
            list2: [],
            loading:false

        };
    },
    created() {
        let act = localStorage.getItem('active2')
        this.active2 = act
        localStorage.removeItem('active2')
        this.union_id = this.$route.query.union_id
        console.log(act)
        if(act){
            if(act=='a'){
            this.getGuildWater(1)
        }else if(act=='b'){
            this.getGuildWater(2)
        }else{
            this.getGuildWater(3)
        }
        }else{
            this.getGuildWater(1)
        }
       
        
    },
    methods: {
        getGuildWater(type) {
            let param = {
                type: type,
                union_id: this.union_id
            }
            // this.loading = true
            GuildWater(param).then(res => {
                //  this.loading = false
                this.list1 = res.data[0]
                this.list2 = res.data[1]
                this.guildInfo = res.unionInfo
            })
        },
        tabClick(name, title) {
            console.log(title)
            if (title == '按日') {
                this.getGuildWater(1)
                this.type =1
                this.active2 = 'a'
                this.tip1 = '今日流水'
                this.tip2 = '昨日流水'
            } else if (title == '按周') {
                this.tip1 = '本周流水'
                this.tip2 = '上周流水'
                this.getGuildWater(2)
                this.type =2
                this.active2 = 'b'
            } else {
                this.tip1 = '本月流水'
                this.tip2 = '上月流水'
                this.getGuildWater(3)
                this.type =3
                this.active2 = 'c'
            }
        },
        onClickDisabled(name, title) {
            this.$toast('正在加急研发中~');
        },
        toUserWater(index){
            localStorage.setItem('active2',this.active2)
            this.$router.push({path:'/userWater?index='+index+'&type='+this.type+'&union_id='+this.union_id})
        }
    }
};
</script>
<style scoped>
.list_box {
    max-width: 100%;
    padding: 0 30px 0 30px;
}

.table_tip {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-size: 28px;
}

.table {
    width: 100%;
}

.top_table,
.bottom_table {
    width: 100%;
    display: flex;
    height: 160px;
}

.table_item {
    flex: 1;
}

.table_title {
    width: 100%;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: #f5f7f9;
}

.table_value {
    width: 100%;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: #ffffff;
}
</style>